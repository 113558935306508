<template>
  <div class="edit-work-material-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'WorkMaterial' }"
        >媒材管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>編輯媒材</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="workForm"
        :model="work_form"
        :rules="work_rules"
        label-position="top"
      >
        <el-form-item label="中文媒材名稱" prop="name_zh">
          <el-input v-model="work_form.name_zh"></el-input>
        </el-form-item>
        <el-form-item label="英文媒材名稱" prop="name_en">
          <el-input v-model="work_form.name_en"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getWorkMaterial, updateWorkMaterial } from "@/api/work-material";

export default {
  name: "EditWorkTag",
  data() {
    return {
      work_form: {
        name_zh: "",
        name_en: "",
      },
      work_rules: {
        name_zh: [{ required: true, message: "請輸入關鍵字", trigger: "blur" }],
        name_en: [{ required: true, message: "請輸入關鍵字", trigger: "blur" }],
      },
    };
  },
  computed: {
    workMaterialId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetWorkMaterial();
  },
  methods: {
    async handleGetWorkMaterial() {
      try {
        const { name_en, name_zh } = await getWorkMaterial(this.workMaterialId);

        this.work_form.name_zh = name_zh;
        this.work_form.name_en = name_en;
      } catch (e) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    handleSubmit() {
      this.$refs.workForm.validate(async (valid) => {
        if (valid) {
          await updateWorkMaterial(this.workMaterialId, this.work_form);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "WorkMaterial" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
